
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.content {
    :global {
        display: block;
        position: relative;
        .video-list-v1-in {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            column-gap: 20px;
            row-gap: 32px;
            margin: 0 auto;
            max-width: var(--max-width-pc);
            padding: var(--padding-pc);
        }
        .video-list-v1-img {
            position: relative;
            margin: 0 auto;
        }
        .video-list-v1-icon {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            &:after {
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                background-color: rgba(0,0,0,.4);
            }
            svg {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 5;
                transform: translate(-50%,-50%);
                width: 40px;
                height: 40px;
            }
        }
        .video-list-v1-title {
            margin-top: 8px;
            font-family: var(--ff-woff2-B);
            font-size: 14px;
            line-height: 1.2;
            color: #fff;
        }
        @include devices(desktop-max) {
            .video-list-v1-in {
                column-gap: 24px;
                row-gap: 48px;
            }
        }
        @include devices(pad) {
            .video-list-v1-in {
                grid-template-columns: 1fr 1fr;
                padding: var(--padding-pad);
                column-gap: 16px;
                row-gap: 24px;
            }
        }
        @include devices(h5) {
            .video-list-v1-in {
                grid-template-columns: 1fr;
                padding: var(--padding-h5);
            }
        }
    }
}