
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.content {
    :global {
        display: flex;
        position: relative;
        justify-content: center;
        gap: 20px;
        .nav-icon-img {
            width: 64px;
            height: 64px;
            margin: 0 auto;
        }
        .nav-icon-title {
            margin-top: 8px;
            text-align: center;
        }
    }
}