
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.youtube {
    :global {
        position: relative;
        z-index: 999999;
        .EditorDialog-dialog {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 222;
            width: 100%;
            height: 100%;
            background-color: #000;
            display: flex;
            align-items: center;
            &.isShow {
                display: flex;
            }
            .EditorDialog-body, .EditorDialog-iframe {
                max-width: 840px;
                max-height: 472px;
                margin: 0 auto;
                width: 100%;
                height: 100%;
            }
            .EditorDialog-body {
                padding: 0 20px;
            }
            .EditorDialog-close {
                position: absolute;
                top: 20px;
                right: 20px;
                font-size: 24px;
                cursor: pointer;
                color: #fff;
            }
        }
    }
}
