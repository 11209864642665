
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.content {
    :global {
        display: block;
        position: relative;
        color: #333;
        .bundles-common-v1-in {
            margin: 0 auto;
            max-width: var(--max-width-pc);
            padding: var(--padding-pad);
        }
        .bundles-common-v1-main-title {
            margin: 0 auto;
            font-family: var(--ff-woff2-EB);
            font-size: 30px;
            line-height: 1.1;
            color: #fff;
            text-align: center;
            margin-bottom: 40px;
        }
        .bundles-common-v1-list {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 20px;
            color: #fff;
        }
        .bundles-common-v1-card {
            width: calc((100% - 40px)/3);
            padding: 30px 30px 162px;
            position: relative;
            border-radius: 10px;
            background-color: #181818;
        }
        .bundles-common-v1-card-title {
            font-family: var(--ff-woff2-EB);
            font-size: 20px;
            line-height: 1.2;
            color: #fff;
            text-align: center;
        }
        .bundles-common-v1-card-img {
            width: 200px;
            height: 200px;
            margin: 0 auto;
        }
        .bundles-common-v1-card-desc1 {
            font-family: var(--ff-woff2-B);
            font-size: 16px;
            line-height: 1.2;
            color: #fff;
        }
        .bundles-common-v1-card-desc2 {
            margin-top: 12px;
            font-family: var(--ff-woff2-M);
            font-size: 14px;
            line-height: 1.3;
        }
        .bundles-common-v1-card-desc3 {
            margin-top: 6px;
            font-family: var(--ff-woff2-M);
            font-size: 14px;
            line-height: 1.3;
            color: #919191;
        }
        .bundles-common-v1-price-box {
            position: absolute;
            bottom: 30px;
            left: 30px;
            right: 30px;
        }
        .bundles-common-v1-card-price {
            display: flex;
            align-items: center;
            gap: 6px;
        }
        .bundles-common-v1-card-price-discount {
            font-family: var(--ff-woff2-EB);
            font-size: 20px;
            line-height: 1.2;
            color: #fff;
        }
        .bundles-common-v1-card-price-original {
            font-family: var(--ff-woff2-M);
            font-size: 16px;
            line-height: 1.2;
            color: #919191;
            text-decoration: line-through;
        }
        .bundles-common-v1-card-price-tips {
            font-family: var(--ff-woff2-M);
            font-size: 12px;
            line-height: 1.3;
            color: #919191;
        }
        .bundles-common-v1-buy-btn {
            background-color: rgba(63,104,224,.95);
            display: block;
            margin-top: 16px;
            border-radius: 30px;
            width: 100%;
            cursor: pointer;
            padding: 16px 24px;
            font-family: var(--ff-woff2-B);
            font-size: 14px;
            line-height: 1.2;
            color: #fff;
            text-align: center;
        }
        .bundles-common-v1-variant-box {
            border-radius: 6px;
            background-color: #222;
            padding: 14px;
            position: relative;
            margin-top: 24px;
            .bundles-common-v1-variant-h {
                font-family: var(--ff-woff2-M);
                font-size: 14px;
                line-height: 1.2;
                color: #f7a40a;
            }
            .bundles-common-v1-variant-c {
                margin-top: 6px;
                display: flex;
                gap: 6px;
            }
            .bundles-common-v1-variant-l {
                width: 50px;
                height: 50px;
            }
            .bundles-common-v1-variant-r {
                flex: 1;
            }
            .bundles-common-v1-variant-t1 {
                font-family: var(--ff-woff2-B);
                font-size: 14px;
                line-height: 1.2;
                color: #fff;
            }
            .bundles-common-v1-variant-price {
                margin-top: 5px;
                display: flex;
                flex-wrap: wrap;
                gap: 6px;
            }
            .bundles-common-v1-variant-price-discount {
                font-family: var(--ff-woff2-EM);
                font-size: 14px;
                line-height: 1.2;
                color: #fff;
            }
            .bundles-common-v1-variant-price-original {
                font-family: var(--ff-woff2-EM);
                font-size: 14px;
                line-height: 1.2;
                color: #919191;
                text-decoration: line-through;
            }
            .bundles-common-v1-variant-f {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .bundles-common-v1-variant-save {
                padding: 4px 8px;
                background-color: #38383d;
                border-radius: 4px;
                font-family: var(--ff-woff2-EM);
                font-size: 12px;
                line-height: 1.2;
                color: #f7a40a;
                white-space: nowrap;
            }
        }
        .bundles-common-v1-calculator {
            display: flex;
            margin-left: 0;
            .bundles-common-v1-calculator-btn {
                width: 28px;
                height: 28px;
                border-radius: 50%;
                cursor: pointer;
                user-select: none;
                font-family: var(--ff-woff2-M);
                font-size: 16px;
                line-height: 1.2;
            }
            .bundles-common-v1-calculator-number {
                width: 46px;
                height: 28px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                padding: 0 5px;
                font-family: var(--ff-woff2-M);
                font-size: 16px;
                line-height: 1.2;
                color: #fff;
                background-color: transparent;
                border: none;
                outline: none;
            }
        }
        .bundles-common-v1-customize {
            display: flex;
            justify-content: center;
            align-items: center;
            .bundles-common-v1-customize-titleC1 {
                font-family: var(--ff-woff2-M);
                font-size: 18px;
                line-height: 1.2;
                color: #f7a40a;
                padding-bottom: 6px;
            }
            .bundles-common-v1-customize-titleC2 {
                font-family: var(--ff-woff2-EB);
                font-size: 28px;
                line-height: 1.2;
                color: #7293f7;
            }
            .bundles-common-v1-customize-btns {
                .editor-link {
                    margin-top: 20px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    font-family: var(--ff-woff2-M);
                    font-size: 18px;
                    line-height: 1.2;
                    color: #3f68e0;
                }
            }
        }
        @include devices(pad) {
            .bundles-common-v1-in {
                padding: 0 48px;
            }
            .bundles-common-v1-list {
                gap: 16px;
            }
            .bundles-common-v1-card {
                width: calc(50% - 8px);
                &.bundles-common-v1-customize {
                    width: 100%;
                }
            }
        }
        @include devices(h5) {
            .bundles-common-v1-in {
                padding: var(--padding-h5);
            }
            .bundles-common-v1-card {
                width: 100%;
            }
            .bundles-common-v1-r {
                margin-top: 10px;
                gap: 10px;
            }
        }
    }
}