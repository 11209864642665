
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.content{
    :global{
        display: block;
        --ServiceModule-theme: #f4f4f4;
        background-color: var(--ServiceModule-theme);
        .service-module-box-title {
            color: var(--ServiceModule-title);
            font-family: var(--ff-woff2-EB);
            font-size: 30px;
            line-height: 1.1;
            text-align: center;
            white-space: pre-line;
            max-width: 792px;
            margin: 0 auto;
        }
        .service-module-box-desc {
            color: var(--ServiceModule-desc);
            font-family: var(--ff-woff2-B);
            font-size: 20px;
            line-height: 1.2;
            text-align: center;
            max-width: 792px;
            margin: 24px auto 0;
            white-space: pre-line;
        }
        // 默认desktop 1024-1440
        .service-module-box{
            padding: 30px 0;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 20px;
            .item{
                width: 180px;
                padding: 14px 20px 43px;
                text-align: center;
                position: relative;
                .icon{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 auto;
                    width: 50px;
                    height: 50px;
                    svg{
                        width: 100%;
                        height: 100%;
                    }
                }
                .title{
                    font-family: var(--ff-woff2-B);
                    font-size: 16px;
                    line-height: 1.2;
                    color: #343434;
                    margin-top: 10px;
                }
                .desc{
                    margin: 6px auto 0;
                    font-family: var(--ff-woff2-M);
                    font-size: 14px;
                    line-height: 1.2;
                    color: #343434;
                    // word-break: break-all;
                }
                .link{
                    position: absolute;
                    left: 0;
                    bottom: 20px;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 2px;
                    color: #3F68E0;
                    margin: 0 auto;
                    &:hover, &:active, &:focus{
                        text-decoration: underline;
                    }
                    .link-text{
                        font-family: var(--ff-woff2-M);
                        font-size: 14px;
                        line-height: 1.2;
                    }
                    .link-icon{
                        width: 13px;
                        height: 13px;
                        html[site='sa'] & {
                            transform: rotate(180deg);
                        }
                        svg{
                            width: 100%;
                            height: 100%;
                            vertical-align: top;
                        }
                    }
                }
            }
            &.theme-black {
                background-color: #1f1f1f;
                .item {
                    .title {
                        color: #fff;
                    }
                    .desc {
                        color: #fff;
                    }
                }
            }
        }

        // > 1440 PC
        @include devices(desktop-max) {
            .service-module-box{
                gap: 24px;
                padding: 40px 0;
                .item{
                    padding: 24px 30px 53px;
                    width: 220px;
                    .icon{
                        width: 60px;
                        height: 60px;
                    }
                    .title{
                        margin-top: 10px;
                    }
                    .link{
                        bottom: 30px;
                    }
                }
            }
            .service-module-box-title {
                font-size: 40px;
            }
        }

        // < 1024
        @include devices(pad) {
            .service-module-box{
                padding: 20px 48px;
                gap: 16px;
                .item{
                    padding: 24px 8px 45px;
                    width: 156px;
                    .title, .desc{
                        color: #1F1F1F;
                    }
                    .icon{
                        width: 48px;
                        height: 48px;
                    }
                    .link{
                        bottom: 22px;
                    }
                }
            }
            .service-module-box-title {
                font-size: 20px;
                padding: 0 24px;
            }
            .service-module-box-desc {
                margin-top: 16px;
                padding: 0 24px;
            }
        }

        // < 768
        @include devices(h5) {
            .service-module-box {
                padding: 20px 24px;
                .item{
                    width: calc(50% - 8px);
                }
            }
            .service-module-box-desc {
                margin-top: 8px;
            }
        }
    }
}