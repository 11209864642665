
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.content {
    :global {
        display: block;
        position: relative;
        text-align: center;
        .product-accessories-title {
            font-size: 24px;
            text-align: center;
            margin-bottom: 24px;
        }
        .product-accessories-card-img {
            width: 218px;
            height: 218px;
            margin: 0 auto;
        }
        .swiper {
            padding: var(--padding-pc);
            .swiper-slide {
                position: relative;
                width: 262px;
                height: auto;
                padding: 30px 22px 98px;
            }
        }
        .product-accessories-card-btn {
            position: absolute;
            bottom: 30px;
            left: 0;
            width: 100%;
            padding: 0 22px;
            text-align: center;
            .editor-link {
                display: block;
                border-radius: 46px;
                padding: 12.5px 20px;
                text-align: center;
                font-size: 16px;
                line-height: 20px;
            }
        }
        @include devices(pad) {
            .swiper {
                padding: var(--padding-pad);
            }
        }
        @include devices(h5) {
            .swiper {
                padding: var(--padding-h5);
            }
        }
    }
    &:global(.product-accessories-theme-black) {
        :global {
            color: #fff;
            .swiper {
                .swiper-slide {
                    background-color: #181818;
                }
            }
            .product-accessories-card-btn {
                .editor-link {
                    background-color: #fff;
                    color: #343434;
                }
            }
        }
    }
}