
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.content {
    :global {
        display: block;
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 99;
        font-size: 14px;

        .product-nav-common-bara {
            padding: 16px 24px;
            line-height: 16px;
            font-size: 14px;
            color: #fff;
            background-color: #3f68e0;
            text-align: center;
        }
        .product-nav-common-nav {
            position: relative;
            background-color: #1f1f1f;
        }
        .product-nav-common-nav-in {
            display: flex;
            margin: 0 auto;
            max-width: var(--max-width-pc);
            padding: var(--padding-pc);
            color: #fff;
            
            .product-nav-common-nav-l {
                position: relative;
                z-index: 3;
                display: flex;
                line-height: 20px;
                padding: 15px 0;
                font-size: 14px;
                .product-nav-common-name1 {
                    color: #949494;
                    svg {
                        display: none;
                    }
                }
                .product-nav-common-name2 {
                    position: relative;
                    margin-left: 29px;
                    color: #fff;
                    &::after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: -14px;
                        width: 1px;
                        height: 15px;
                        transform: translateY(-50%);
                        background-color: #fff;
                    }
                }
            }
            .product-nav-common-nav-c {
                position: relative;
                z-index: 2;
                margin-left: auto;
                display: flex;
                line-height: 20px;
                padding: 15px 0;
                gap: 30px;
                color: #949494;
                .editor-link {
                    &.product-nav-common-active {
                        color: #fff;
                    }
                }
            }
            .product-nav-common-nav-r {
                display: flex;
                align-items: center;
                .product-nav-common-nav-buy {
                    padding: 8px 20px;
                    border-radius: 30px;
                    background-color: rgba(63,104,224,.95);
                    font-size: 14px;
                    line-height: 20px;
                    color: #fff;
                    cursor: pointer;
                    margin-left: 30px;
                }
            }
        }
        
        @include devices(desktop-max) {
            
        }
        @media ((width > 1024px) and (width < 1200px)) {
            .product-nav-common-nav-in {
                .product-nav-common-nav-l {
                    font-size: 12px;
                    .product-nav-common-name2 {
                        margin-left: 12px;
                        &::after {
                            left: -7px;
                        }
                    }
                }
                .product-nav-common-nav-c {
                    gap: 12px;
                }
                .product-nav-common-nav-r {
                    .product-nav-common-nav-buy {
                        margin-left: 12px;
                        padding: 4px 12px;
                    }
                }
            }
        }
        @include devices(pad) {
            .product-nav-common-nav-in {
                flex-wrap: wrap;
                padding: var(--padding-pad);
                .product-nav-common-nav-l {
                    margin-right: auto;
                    .product-nav-common-others {
                        position: absolute;
                        top: 100%;
                        left: 0;
                        padding: var(--padding-pad);
                        line-height: 48px;
                        background-color: #1f1f1f;
                        display: none;
                        width: calc(100vw - 268px);
                        padding: 0;
                    }
                    .product-nav-common-name1 {
                        display: flex;
                        align-items: center;
                        gap: 20px;
                        svg {
                            display: block;
                            transition: transform 0.5s;
                        }
                    }
                    .product-nav-common-name2 {
                        margin-left: 0;
                        &::after {
                            display: none;
                        }
                    }
                }
                .product-nav-common-nav-c {
                    order: 3;
                    width: 100%;
                }
                .product-nav-common-nav-r {
                    .product-nav-common-nav-buy {
                        margin-left: 0;
                    }
                }
                &.product-nav-common-l-active {
                    .product-nav-common-nav-l {
                        .product-nav-common-name1 {
                            svg {
                                transform: rotate(180deg);
                            }
                        }
                        .product-nav-common-others {
                            display: block;
                        }
                    }
                }
            }
        }
        @include devices(h5) {
            .product-nav-common-nav-in {
                width: auto;
                padding: var(--padding-h5);
                .product-nav-common-nav-l {
                    .product-nav-common-others {
                        width: calc(100vw - 24px);
                        padding: 0;
                    }
                }
                .product-nav-common-nav-c {
                    justify-content: flex-start;
                    overflow-x: auto;
                    flex-wrap: nowrap;
                    &::-webkit-scrollbar {
                        display: none;
                    }
                }
            }
        }
    }
}