
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.content {
    :global {
        display: block;
        position: relative;
        margin: 0 auto;
        padding: 0 24px;
    }
    &:global(.form-common-v1-theme-black) {
        :global {
            background-color: #181818;
        }
    }
}
