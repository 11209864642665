
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.content {
    :global {
        display: block;
        position: relative;

        .product-scene-a-info {
            position: relative;
            width: var(--text-width-pc);
            margin: 0 auto;
            .product-scene-a-title1 {
                font-family: var(--ff-woff2-EB);
                font-size: 16px;
                line-height: 1.2;
                color: #fff;
                opacity: 0;
                transform: translateY(120px);
                transition: opacity .8s linear, transform .4s cubic-bezier(.26,.67,.48,.91);
            }
            .product-scene-a-title2 {
                margin-top: 8px;
                font-family: var(--ff-woff2-EB);
                font-size: 30px;
                line-height: 1.1;
                color: #fff;
                opacity: 0;
                transform: translateY(120px);
                transition: opacity .8s linear, transform .4s cubic-bezier(.26,.67,.48,.91);
            }
            .product-scene-a-title3 {
                margin-top: 16px;
                font-family: var(--ff-woff2-B);
                font-size: 18px;
                line-height: 1.2;
                color: #949494;
                opacity: 0;
                transform: translateY(120px);
                transition: opacity .8s linear, transform .4s cubic-bezier(.26,.67,.48,.91);
            }
            .product-scene-a-more {
                margin-top: 12px;
                display: flex;
                align-items: center;
                color: #3f68e0;
                opacity: 0;
                transform: translateY(120px);
                transition: opacity .8s linear 0s, transform .4s cubic-bezier(.26,.67,.48,.91) 0s;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .product-scene-a-img {
            margin: 40px auto 0;
            max-width: var(--max-width-pc);
            padding: var(--padding-pc);
            height: 484px;
        }
        .product-scene-a-in {
            color: black;
            &.product-scene-a-theme-white {
                color: white;
            }
            &.isEnter {
                .product-scene-a-title1, .product-scene-a-title2, .product-scene-a-title3, .product-scene-a-more {
                    opacity: 1;
                    transform: translateY(0);
                }
                .product-scene-a-title1 {
                    transition: opacity .8s linear 0s, transform .4s cubic-bezier(.26,.67,.48,.91) 0s;
                }
                .product-scene-a-title2 {
                    transition: opacity .8s linear 0.1s, transform .4s cubic-bezier(.26,.67,.48,.91) 0.1s;
                }
                .product-scene-a-title3 {
                    transition: opacity .8s linear 0.2s, transform .4s cubic-bezier(.26,.67,.48,.91) 0.2s;
                }
                .product-scene-a-more {
                    transition: opacity .8s linear 0.2s, transform .4s cubic-bezier(.26,.67,.48,.91) 0.2s;
                }
            }
        }
        
        @include devices(desktop-max) {
            .product-scene-a-info {
                width: var(--text-width-max);
                padding-right: 204px;
                .product-scene-a-title1 {
                    font-size: 20px;
                }
                .product-scene-a-title2 {
                    font-size: 40px;
                    margin-top: 12px;
                }
                .product-scene-a-title3 {
                    font-size: 20px;
                    margin-top: 20px;
                }
            }
            .product-scene-a-img {
                height: 600px;
                margin-top: 50px;
            }
        }
        @include devices(pad) {
            .product-scene-a-info {
                width: auto;
                padding: var(--padding-pad);
                .product-scene-a-title1 {
                    font-size: 16px;
                }
                .product-scene-a-title2 {
                    font-size: 24px;
                }
                .product-scene-a-title3 {
                    margin-top: 20px;
                    font-size: 16px;
                }
            }
            .product-scene-a-img {
                padding: var(--padding-pad);
                height: 270px;
            }
        }
        @include devices(h5) {
            .product-scene-a-info {
                padding: var(--padding-h5);
            }
            .product-scene-a-img {
                padding: var(--padding-h5);
            }
        }
    }
}