
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.content {
    :global {
        display: block;
        position: relative;
        background-color: #fff;
        padding: 40px 0;
        .shopify-card-in {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 20px;
            margin: 0 auto;
            max-width: 1248px;
            padding: 0 24px;
            font-family: var(--ff-woff2-M);
        }
        .shopify-card-item {
            background-color: #f5f5f5;
            border-radius: 12px;
            .shopify-order-top {
                right: 28px;
                top: 38px;
            }
        }
        .shopify-card-item-l {
            position: relative;
            height: 328px;
        }
        .shopify-card-item-r {
            text-align: center;
        }
        .shopify-card-item-title {
            font-size: 16px;
            line-height: 24px;
        }
        .shopify-card-item-price1 {
            font-family: var(--ff-woff2-B);
            font-size: 18px;
            color: #FF8D00;
            line-height: 38px;
            display: flex;
            margin: 4px auto 0;
            justify-content: center;
            span:nth-child(2) {
                font-size: 32px;
            }
        }
        .shopify-card-item-price2 {
            color: #949491;
            font-size: 16px;
            line-height: 1.3;
            text-decoration: line-through;
        }
        .shopify-card-item-btns {
            display: flex;
            justify-content: center;
            gap: 20px;
            margin-top: 13px;
            padding-bottom: 20px;
        }
        .shopify-card-item-btn1, .shopify-card-item-btn2 {
            min-width: 156px;
            height: 40px;
            line-height: 40px;
            padding: 0;
            max-width: 180px;
            border-radius: 33px;
            font-size: 16px;
            text-align: center;
            border: 1px solid #000;
        }
        .shopify-card-item-btn1:hover {
            background-color: #000;
            color: #fff;
        }
        .shopify-card-item-btn2 {
            background-color: #000;
            color: #fff;
        }
        @include devices(pad) {
            .shopify-card-in {
                padding: var(--padding-pad);
                grid-template-columns: 1fr;
            }
            .shopify-card-item {
                display: block;
                padding-bottom: 20px;
            }
            .shopify-card-item-title {
                padding: 0 24px;
            }
            .shopify-card-item-btn1, .shopify-card-item-btn2 {
                min-width: auto;
                padding: 0 24px;
            }
        }
        @include devices(h5) {
            .shopify-card-in {
                padding: var(--padding-h5);
            }
        }
    }
}