
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.content {
    :global {
        display: block;
        margin: 0 auto;
        .JoinUs-title {
            margin: 0 auto;
            width: calc(100vw - 632px);
            font-family: var(--ff-woff2-EB);
            font-size: 28px;
            line-height: 1.1;
            text-align: center;
            color: #000;
        }
        .JoinUs-in {
            margin: 40px auto 0;
            width: calc(100vw - 256px);
            display: flex;
            gap: 7vw;
            align-items: center;
        }
        .JoinUs-l {
            width: 40vw;
            height: 35vw;
            border-radius: 14px;
            overflow: hidden;
        }
        .JoinUs-r {
            flex: 1;
        }
        .JoinUs-item {
            margin-top: 40px;
            cursor: pointer;
            &:first-child {
                margin-top: 0 !important;
            }
            &.JoinUs-active {
                .JoinUs-item-title {
                    svg {
                        transform: rotate(0);
                    }
                }
                .JoinUs-item-box {
                    display: block;
                }
            }
        }
        .JoinUs-item-title {
            position: relative;
            font-family: var(--ff-woff2-B);
            font-size: 20px;
            line-height: 1.2;
            color: #000;
            padding-right: 24px;
            svg {
                position: absolute;
                top: 0;
                right: 0;
                font-size: 24px;
                transform: rotate(180deg);
                transition: transform 0.4s ease;
            }
        }
        .JoinUs-item-title-in {
            width: 100%;
            padding-right: 50px;
        }
        .JoinUs-item-desc {
            margin-top: 20px;
            font-family: var(--ff-woff2-M);
            font-size: 16px;
            line-height: 1.2;
            color: #343434;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: pre-line;
        }
        .JoinUs-item-link {
            margin-top: 12px;
            font-family: var(--ff-woff2-M);
            font-size: 16px;
            line-height: 1.2;
            color: #3F68E0;
            svg {
                vertical-align: top;
                margin-top: 3px;
            }
            &:hover, &:active, &:focus {
                text-decoration: underline;
            }
        }
        .JoinUs-item-box {
            padding-right: 90px;
            display: none;
        }
        .JoinUs-h5 {
            display: none;
        }

        @include devices(desktop-max) {
            .JoinUs-title {
                font-size: 34px;
                width: 792px;
            }
            .JoinUs-in{
                margin-top: 50px;
                width: 1200px;
                gap: 102px;
                .JoinUs-l{
                    width: 588px;
                    height: 520px;
                    border-radius: 16px;
                }
                .JoinUs-r{
                    .JoinUs-item{
                        margin-top: 48px;
                        .JoinUs-item-title{
                            font-size: 24px;
                            line-height: 1.1;
                            padding-right: 28px;
                            .JoinUs-item-title-in {
                                max-width: 432px;
                                padding-right: 0;
                            }
                            svg{
                                width: 28px;
                                height: 28px;
                                margin-top: -1.5px;
                            }
                        }
                        .JoinUs-item-box{
                            padding-right: 0;
                            .JoinUs-item-desc{
                                max-width: 432px;
                                margin-top: 24px;
                                font-size: 18px;
                            }
                            .JoinUs-item-link{
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
        }

        @include devices(pad) {
            .JoinUs-title {
                font-size: 22px;
                width: calc(100vw - 268px);
            }
            .JoinUs-in{
                margin-top: 30px;
                width: calc(100vw - 96px);
                gap: 50px;
                .JoinUs-l{
                    width: 327px;
                    height: 290px;
                    border-radius: 10px;
                }
                .JoinUs-r{
                    .JoinUs-item{
                        margin-top: 24px;
                        .JoinUs-item-title{
                            font-size: 16px;
                            padding-right: 16px;
                            svg{
                                width: 16px;
                                height: 16px;
                                margin-top: 1.5px;
                            }
                        }
                        .JoinUs-item-box{
                            padding-right: 46px;
                            .JoinUs-item-desc{
                                margin-top: 12px;
                                font-size: 14px;
                            }
                            .JoinUs-item-link{
                                margin-top: 8px;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }

        @include devices(h5) {
            text-align: center;
            overflow: hidden;
            .JoinUs-pc {
                display: none !important;
            }
            .JoinUs-h5 {
                display: block !important;
                width: calc(100vw - 48px);
                padding: 0;
            }
           .JoinUs-title{
                margin: 0 auto;
                width: calc(100vw - 48px);
                font-family: var(--ff-woff2-EB);
                font-size: 22px;
                line-height: 1.1;
                color: #000;
           }
           .swiper-box{
               margin: 30px auto 0;
               padding-bottom: 38px;
               .swiper-pagination{
                   bottom: 0;
               }
               .swiper-wrapper{
                   .swiper-item{
                       .JoinUs-item{
                           white-space: pre-line;
                           .JoinUs-item-img{
                               width: 100%;
                               height: 77.34vw;
                               border-radius: 8px;
                                overflow: hidden;
                           }
                           .JoinUs-item-title{
                               margin-top: 24px;
                               padding: 0;
                               font-family: var(--ff-woff2-B);
                               font-size: 18px;
                               line-height: 1.2;
                               color: #000;
                           }
                           .JoinUs-item-box{
                                padding: 0;
                                display: block;
                                .JoinUs-item-desc{
                                    margin-top: 10px;
                                    font-family: var(--ff-woff2-M);
                                    font-size: 14px;
                                    line-height: 1.2;
                                    color: #000;
                                }
                                .JoinUs-item-link{
                                    margin-top: 24px;
                                    svg{
                                        width: 13px;
                                        height: 13px;
                                        margin-top: 3px;
                                        margin-left: 4px;
                                        vertical-align: top;
                                    }
                                }
                           }
                       }
                   }
               }
           }
        }
    }
    &:global(.temp-JoinUs-length-1) {
        :global {
            .JoinUs-item-title {
                svg {
                    display: none;
                }
            }
        }
    }
}