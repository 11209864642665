
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.content {
    :global {
        display: block;
        width: calc(100vw - 256px);
        margin: 0 auto;
        padding: 0;
        background-color: #f3f4f7;
        overflow: hidden;

        .ProductScenario-imgbox > .editor-link {
            display: block;
        }
        .ProductScenario-item-active {
            border: 2px solid #2468f2;
            z-index: 99;
        }
        .ProductScenario-title {
            color: #000;
            text-align: center;
            font-size: 28px;
            line-height: 1.1;
            font-family: var(--ff-woff2-EB);
            padding: 0 114px;
        }
        .ProductScenarioRound-content {
            padding: 40px 0 0;
            scrollbar-width: none;
        }
        .round-2 {
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 1110px;
            max-width: 100%;
            margin: 0 auto;
            gap: 26px;
            .ProductScenario-item2-left,
            .ProductScenario-item2-right {
                position: relative;
                border-radius: 14px;
                height: 500px;
                overflow: hidden;
            }
            .ProductScenario-item2-left {
                width: 653px;
            }
            .ProductScenario-item2-right {
                width: 433px;
            }
            .ProductScenario-item2-text {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                padding: 30px;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 29%, rgba(0, 0, 0, 0.5) 63%, #000 100%);
            }
            .ProductScenario-item2-title {
                color: #fff;
                font-family: var(--ff-woff2-B);
                font-size: 20px;
                font-weight: 500;
                line-height: 1.3;
                white-space: pre-wrap;
                word-break: break-word;
                margin-bottom: 10px;
            }
            .ProductScenario-item2-desc {
                color: #cfd0d2;
                font-family: var(--ff-woff2-M);
                font-size: 14px;
                line-height: 1.28;
                margin-bottom: 20px;
                white-space: pre-wrap;
            }
            .ProductScenario-item2-btns {
                display: flex;
                gap: 20px;
                .ProductScenario-item2-btn1 {
                    display: flex;
                    padding: 6px 16px;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    font-family: var(--ff-woff2-M);
                    font-size: 14px;
                    border-radius: 4px;
                    border: 1px solid #fff;
                    cursor: pointer;
                    html[site='it'] &,
                    html[site='de'] &,
                    html[site='fr'] &,
                    html[site='es'] & {
                        font-size: 12px;
                    }
                }
                .ProductScenario-item2-btn2 {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    color: #fff;
                    font-family: var(--ff-woff2-M);
                    font-size: 14px;
                    cursor: pointer;
                    html[site='it'] &,
                    html[site='de'] &,
                    html[site='fr'] &,
                    html[site='es'] & {
                        font-size: 12px;
                    }
                }
            }
        }

        .round-3 {
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            max-width: 100%;
            width: 1110px;
            margin: 0 auto;
            gap: 24px;
            .ProductScenario-item3-left,
            .ProductScenario-item3-right-card {
                position: relative;
                border-radius: 14px;
                overflow: hidden;
            }
            .ProductScenario-item3-left {
                width: 706px;
                height: 540px;
            }
            .ProductScenario-item3-right {
                width: 380px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: 24px;
                flex: 1 0 auto;
            }
            .ProductScenario-item3-right .ProductScenario-item3-right-card {
                height: 258px;
            }
            .ProductScenario-item3-text {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                padding: 30px;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 29%, rgba(0, 0, 0, 0.5) 63%, #000 100%);
            }
            .ProductScenario-item3-title {
                color: #fff;
                font-family: var(--ff-woff2-B);
                font-size: 20px;
                font-weight: 500;
                line-height: 1.3;
                white-space: pre-wrap;
                word-break: break-word;
                margin-bottom: 10px;
            }
            .ProductScenario-item3-desc {
                color: #cfd0d2;
                font-family: var(--ff-woff2-M);
                font-size: 14px;
                line-height: 1.28;
                margin-bottom: 20px;
                white-space: pre-wrap;
            }
            .ProductScenario-item3-btns {
                display: flex;
                gap: 20px;
                .ProductScenario-item3-btn1 {
                    display: flex;
                    padding: 6px 16px;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    font-family: var(--ff-woff2-M);
                    font-size: 14px;
                    border-radius: 4px;
                    border: 1px solid #fff;
                    cursor: pointer;
                    html[site='it'] &,
                    html[site='de'] &,
                    html[site='fr'] &,
                    html[site='es'] & {
                        font-size: 12px;
                    }
                }
                .ProductScenario-item3-btn2 {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    color: #fff;
                    font-family: var(--ff-woff2-M);
                    font-size: 14px;
                    cursor: pointer;
                    html[site='it'] &,
                    html[site='de'] &,
                    html[site='fr'] &,
                    html[site='es'] & {
                        font-size: 12px;
                    }
                }
            }
        }

        .round-4 {
            display: flex;
            width: 100%;
            margin: 0 auto;
            align-items: flex-start;
            justify-content: space-between;
            column-gap: 24px;
            row-gap: 26px;
            flex-wrap: wrap;
            overflow-x: auto;
            .ProductScenario-item4 {
                --width: calc((100% - 24px) / 2);
                width: var(--width);
                height: calc(var(--width) / 1.78);
                position: relative;
                border-radius: 14px;
                overflow: hidden;
            }
            .ProductScenario-item4-text {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                padding: 30px;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 29%, rgba(0, 0, 0, 0.5) 63%, #000 100%);
            }
            .ProductScenario-item4-title {
                color: #fff;
                font-family: var(--ff-woff2-B);
                font-size: 20px;
                font-weight: 500;
                line-height: 1.3;
                white-space: pre-wrap;
                word-break: break-word;
                margin-bottom: 10px;
            }
            .ProductScenario-item4-desc {
                color: #cfd0d2;
                font-family: var(--ff-woff2-M);
                font-size: 14px;
                line-height: 1.28;
                margin-bottom: 20px;
                white-space: pre-wrap;
            }
            .ProductScenario-item4-btns {
                display: flex;
                gap: 20px;
                .ProductScenario-item4-btn1 {
                    display: flex;
                    padding: 6px 16px;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    font-family: var(--ff-woff2-M);
                    font-size: 14px;
                    border-radius: 4px;
                    border: 1px solid #fff;
                    cursor: pointer;
                    html[site='it'] &,
                    html[site='de'] &,
                    html[site='fr'] &,
                    html[site='es'] & {
                        font-size: 12px;
                    }
                }
                .ProductScenario-item4-btn2 {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    color: #fff;
                    font-family: var(--ff-woff2-M);
                    font-size: 14px;
                    cursor: pointer;
                    html[site='it'] &,
                    html[site='de'] &,
                    html[site='fr'] &,
                    html[site='es'] & {
                        font-size: 12px;
                    }
                }
            }
        }
        @include devices(desktop-max) {
            width: 1200px;

            .ProductScenario-title {
                font-size: 34px;
                width: 792px;
                margin: 0 auto;
                padding: 0;
            }
            .round-2 {
                width: 1200px;

                .ProductScenario-item2-left,
                .ProductScenario-item2-right {
                    height: 540px;
                }
                .ProductScenario-item2-left {
                    width: 706px;
                }
                .ProductScenario-item2-right {
                    width: 468px;
                }
            }
            .round-3 {
                width: 1200px;
                .ProductScenario-item3-right .ProductScenario-item3-right-card {
                    width: 468px;
                    height: 257px;
                }
            }
            .round-4 {
                min-width: 1200px;
                .ProductScenario-item4 {
                    width: 588px;
                    height: 330px;
                }
            }
        }
        @include devices(pad) {
            & {
                width: 100vw;
                padding: 0;
                margin-left: auto;
                margin-right: auto;
            }
            .ProductScenario-title {
                font-size: 22px;
            }
            .ProductScenarioRound-content {
                overflow-x: auto;
                padding: 30px 48px 0;
                scrollbar-width: none;
                gap: 16px;
                &::-webkit-scrollbar {
                    display: none;
                    scrollbar-width: 0;
                }
            }
            .round-2 {
                justify-content: flex-start;
                .ProductScenario-item2-left,
                .ProductScenario-item2-right {
                    --item2-width: calc((100vw - 16px) / 2);
                    width: var(--item2-width);
                    height: calc(var(--item2-width) * 1.2);
                    border-radius: 8px;
                }
                .ProductScenario-item2-text {
                    padding: 20px;
                }
                .ProductScenario-item2-title {
                    font-size: 16px;
                }
                .ProductScenario-item2-desc {
                    font-size: 16px;
                }
            }
            .round-3 {
                max-width: none;
                width: auto;
                overflow-x: auto;
                justify-content: flex-start;

                &::-webkit-scrollbar {
                    display: none;
                    scrollbar-width: 0;
                }

                .ProductScenario-item3-left,
                .ProductScenario-item3-right .ProductScenario-item3-right-card {
                    border-radius: 8px;
                }

                .ProductScenario-item3-left,
                .ProductScenario-item3-right,
                .ProductScenario-item3-right .ProductScenario-item3-right-card {
                    --item2-width: calc((100vw - 16px) / 2);
                    width: var(--item2-width);
                    flex: 1 0 var(--item2-width);
                    height: calc(var(--item2-width) * 1.2);
                }
                .ProductScenario-item3-right {
                    flex-direction: row;
                    justify-content: space-around;
                }
                .ProductScenario-item2-text {
                    padding: 20px;
                }
                .ProductScenario-item2-title {
                    font-size: 16px;
                }
                .ProductScenario-item2-desc {
                    font-size: 16px;
                }
            }
            .round-4 {
                max-width: none;
                width: auto;
                overflow-x: auto;
                gap: 16px;
                flex-wrap: nowrap;
                justify-content: flex-start;

                &::-webkit-scrollbar {
                    display: none;
                    scrollbar-width: 0;
                }
                .ProductScenario-item4 {
                    width: 328px;
                    flex: 1 0 328px;
                    height: 374px;
                    border-radius: 8px;
                }
                .ProductScenario-item4-title {
                    font-size: 14px;
                }
                .ProductScenario-item4-desc {
                    font-size: 12px;
                    white-space: pre-wrap;
                }
                .ProductScenario-item2-text {
                    padding: 20px;
                }
                .ProductScenario-item2-title {
                    font-size: 16px;
                }
                .ProductScenario-item2-desc {
                    font-size: 16px;
                }
            }
        }
        @include devices(h5) {
            padding: 0;
            .ProductScenario-title {
                padding: 0;
            }
            .round-2 {
                display: flex;
                padding: 30px 24px 0;
                gap: 16px;

                .ProductScenario-item2-left,
                .ProductScenario-item2-right {
                    width: 280px;
                    height: 374px;
                    flex: 1 0 280px;
                    border-radius: 8px;
                }
                .ProductScenario-item2-text {
                    padding: 20px;
                }
                .ProductScenario-item2-title {
                    font-size: 16px;
                }
                .ProductScenario-item2-desc {
                    font-size: 16px;
                }
            }

            .round-3 {
                display: flex;
                padding: 30px 24px 0;
                gap: 16px;
                border-radius: 8px;

                .ProductScenario-item3-left,
                .ProductScenario-item3-right .ProductScenario-item3-right-card {
                    border-radius: 8px;
                }

                .ProductScenario-item3-right .ProductScenario-item3-right-card {
                    width: 280px;
                    height: 374px;
                }

                .ProductScenario-item3-left {
                    width: 280px;
                    height: 374px;
                    flex: 1 0 280px;
                }

                .ProductScenario-item3-right {
                    width: auto;
                    height: 374px;
                    flex: 1 0 auto;
                }
                .ProductScenario-item2-text {
                    padding: 20px;
                }
                .ProductScenario-item2-title {
                    font-size: 16px;
                }
                .ProductScenario-item2-desc {
                    font-size: 16px;
                }
            }

            .round-4 {
                display: flex;
                padding: 30px 24px 0;
                gap: 16px;

                .ProductScenario-item4 {
                    width: 280px;
                    height: 374px;
                    flex: 1 0 280px;
                    border-radius: 8px;
                }
                .ProductScenario-item2-text {
                    padding: 20px;
                }
                .ProductScenario-item2-title {
                    font-size: 16px;
                }
                .ProductScenario-item2-desc {
                    font-size: 16px;
                }
            }
        }
    }
}
