
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.content {
    :global {
        display: block;
        position: relative;
        color: #7a7d80;
        .tab-sign-a-box {
            margin: 0 auto;
            max-width: var(--max-width-pc);
            padding: var(--padding-pc);
        }
        .tab-sign-a-h {
            width: max-content;
            display: flex;
            justify-content: center;
            margin: 0 auto;
            font-size: 18px;
            gap: 60px;
            border-bottom: 0.5px solid #343434;
            .tab-sign-a-h-item {
                position: relative;
                padding: 14px 0;
                cursor: pointer;
                &.tab-sign-a-active {
                    color: #fff;
                    &::after {
                        content: '';
                        position: absolute;
                        bottom: -1px;
                        left: 0;
                        width: 100%;
                        height: 2px;
                        background-color: #fff;
                    }
                }
            }
        }
        .tab-sign-a-c {
            .tab-sign-a-c-item {
                display: none;
                &.tab-sign-a-active {
                    display: block;
                }
            }
        }
        @include devices(desktop-max) {
            
        }
        @include devices(pad) {
            .tab-sign-a-box {
                padding: var(--padding-pad);
            }
        }
        @include devices(h5) {
            .tab-sign-a-box {
                padding: var(--padding-h5);
            }
        }
    }
    &:global(.tab-sign-a-theme-black) {
        :global {
            color: #7a7d80;
        }
    }
}