
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.imgvideoPc {
    :global {
        position: relative;
    }
}

.imgvideoH5 {
    :global {
        display: none;
        @include devices(pad) {
            display: block;
        }
    }
}
