
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.content{
    :global{
        display: block;
        // 默认desktop
        .OurStory-box{
            position: relative;
            width: 100%;
            height: 600px;
            text-align: center;
            * {
                color: inherit;
            }
            &.OurStory-pos-left {
                text-align: left;
            }
            &.OurStory-pos-right {
                text-align: right;
            }
            .OurStory-img {
                position: relative;
                width: 100%;
                height: 100%;
            }
            .content-box {
                position: absolute;
                top: 50%;
                left: 0;
                width: 100%;
                transform: translateY(-50%);
                .content-box-in {
                    width: calc(100vw - 632px);
                    margin: 0 auto;
                }
                h2, p{
                    color: inherit;
                }
                .title{
                    font-family: var(--ff-woff2-EB);
                    font-size: 28px;
                    line-height: 1.1;
                    white-space: pre-line;
                }
                .content{
                    margin-top: 40px;
                    font-family: var(--ff-woff2-M);
                    font-size: 16px;
                    line-height: 1.2;
                    white-space: pre-line;
                }
                .btn{
                    margin: 50px auto 0;
                    display: inline-block;
                    vertical-align: top;
                    padding: 13px 22px;
                    max-width: 100%;
                    background-color: #fff;
                    color: #000;
                    font-family: var(--ff-woff2-M);
                    font-size: 16px;
                    line-height: 1.2;
                    cursor: pointer;
                    border-radius: 4px;
                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }

        // > 1440 PC
        @include devices(desktop-max) {
            .OurStory-box{
                height: 704px;
                .content-box{
                    .content-box-in{
                        width: 792px;
                        .title{
                            font-size: 40px;
                        }
                        .content{
                            font-size: 20px;
                        }
                        .btn{
                            margin-top: 60px;
                            font-size: 18px;
                        }
                    }
                }
            }
        }

        // < 1024
        @include devices(pad) {
            .OurStory-box{
                .content-box{
                    .content-box-in {
                        width: calc(100% - 268px);
                    }
                    .title{
                        font-size: 22px;
                    }
                    .content{
                        margin-top: 30px;
                        font-size: 16px;
                    }
                    .btn{
                        margin-top: 40px;
                        font-size: 14px;
                        padding: 14px 32px;
                    }
                }
            }
        }

        // < 768
        @include devices(h5) {
            .OurStory-box{
                .content-box{
                    .content-box-in{
                        width: calc(100vw - 48px);
                    }
                    .content{
                        font-size: 14px;
                    }
                    .btn{
                        margin-top: 60px;
                    }
                }
            }
        }
    }
    &:global(.temp-OurStory-theme-black) {
        :global {
            .editor-link.btn {
                color: #fff;
            }
        }
    } 
}