
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.content {
    :global {
        display: block;
        position: relative;
        .swiper-list-a-title {
            margin: 0 auto 24px;
            max-width: var(--max-width-pc);
            padding: var(--padding-pc);
        }
        .swiper {
            margin: 0 auto;
            max-width: var(--max-width-pc);
            padding: var(--padding-pc);
            .swiper-slide {
                position: relative;
                width: 733px;
                height: 408px;
            }
        }
        .swiper-list-a-card-title {
            position: absolute;
            bottom: 24px;
            left: 0;
            width: 100%;
            padding: var(--padding-h5);
        }
        
        
        @include devices(desktop-max) {
            
        }
        @include devices(pad) {
            .swiper-list-a-title {
                width: auto;
                padding: var(--padding-pad);
            }
            .swiper {
                padding: var(--padding-pad);
                .swiper-slide {
                    width: 241px;
                    height: 250px;
                }
            }
        }
        @include devices(h5) {
            .swiper-list-a-title {
                width: auto;
                padding: var(--padding-h5);
            }
            .swiper {
                width: auto;
                padding: var(--padding-h5);
            }
        }
    }
    &:global(.swiper-list-a-theme-black) {
        :global {
            color: #fff;
        }
    }
}