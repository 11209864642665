
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.content {
    :global {
        display: block;
        position: relative;
        height: calc(100vh + 300px);
        color: white;
        .lowcode-img {
            height: 100%;
        }
        .sticky-one-a-mask {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            background-color: #000;
            transition: opacity .4s linear 0s;
        }
        .sticky-one-a-info {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            transform: translateY(-50%);
            text-align: center;
            h2 {
                font-family: var(--ff-woff2-EB);
                margin: 0 auto;
                width: var(--text-width-pc);
                font-size: 36px;
                line-height: 1.2;
                white-space: pre-line;
                opacity: 0;
                transform: translateY(100px);
                transition: opacity .8s linear 0s, transform .4s cubic-bezier(.26,.67,.48,.91) 0s;
            }
        }
        .sticky-content {
            position: sticky;
            top: 0;
            left: 0;
            height: 100vh;
            &.isEnter {
                .sticky-one-a-mask {
                    opacity: 0.2;
                }
                .sticky-one-a-info h2 {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }
        @include devices(desktop-max) {
            .sticky-one-a-info {
                h2 {
                    width: var(--text-width-max);
                    font-size: 48px;
                }
            }
        }
        @include devices(pad) {
            .sticky-one-a-info {
                h2 {
                    width: auto;
                    padding: var(--padding-pad);
                }
            }
        }
        @include devices(h5) {
            .sticky-one-a-info {
                h2 {
                    padding: var(--padding-h5);
                }
            }
        }
    }
    &:global(.sticky-one-a-theme-black) {
        :global {
            color: black;
        }
    }
}