
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.content {
    :global {
        display: block;
        width: calc(100vw - 104px);
        margin: 0 auto;
        padding: 48px 150px;
        background-color: #F3F4F7;
        .ProductScenario-imgbox > .editor-link {
            display: block;
        }
        .ProductScenario-title {
            color: #000;
            text-align: center;
            font-size: 28px;
            line-height: 1.1;
            font-family: var(--ff-woff2-EB);
            padding: 0 114px;
        }
        .ProductScenario-tabh {
            padding-bottom: 0;
            margin-top: 24px;
            overflow-x: auto;
            text-align: center;
            &::-webkit-scrollbar {
                width: 0px; /* 滚动条宽度 */
            }
        }
        .ProductScenario-tabh-in {
            display: inline-block;
            white-space: nowrap;
            text-align: center;
            border-bottom: 1px solid #CECFD2;
        }
        .ProductScenario-tabh-item {
            position: relative;
            display: inline-block;
            vertical-align: top;
            padding-bottom: 10px;
            margin: 0 35px;
            font-family: var(--ff-woff2-B);
            font-size: 16px;
            line-height: 1.2;
            white-space: nowrap;
            color: #7A7D80;
            cursor: pointer;
            &:hover {
                opacity: 0.85;
            }
            &.ProductScenario-active {
                color: #000;
                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background-color: #000;
                }
            }
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }
        .ProductScenario-tabc {
            margin-top: 34px;
        }
        .ProductScenario-tabc-item {
            display: none;
            &.ProductScenario-active {
                display: block;
            }
            &.ProductScenario-pos-left {
                .ProductScenario-msbox-l {
                    text-align: left;
                    html[site='sa'] & {
                        text-align: right;
                    }
                }
            }
            &.ProductScenario-pos-right {
                .ProductScenario-msbox-l {
                    order: 2;
                    text-align: right;
                }
                .ProductScenario-msbox-r {
                    order: 1;
                }
            }
        }
        .ProductScenario-imgbox {
            position: relative;
            display: block;
            margin: 0 auto;
            .lowcode-img {
                height: 498px;
                border-radius: 16px;
                overflow: hidden;
            }
        }
        .ProductScenario-msbox {
            position: absolute;
            bottom: 36px;
            left: 0;
            width: 100%;
            display: flex;
            padding: 0 60px;
            gap: 115px;
            color: #fff;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            .ProductScenario-ms-h {
                margin: 0 auto;
                font-family: var(--ff-woff2-B);
                font-size: 28px;
                line-height: 1.1;
            }
            .ProductScenario-ms-c {
                font-family: var(--ff-woff2-M);
                font-size: 16px;
                line-height: 1.2;
                margin-top: 16px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            .editor-link {
                min-width: 128px;
                margin: 0 auto;
                padding: 10px 24px;
                text-align: center;
                font-family: var(--ff-woff2-M);
                border-radius: 4px;
                font-size: 16px;
                line-height: 1.2;
                white-space: nowrap;
            }
        }
        @include devices(desktop-max) {
            width: 1400px;
            padding: 63px 178px;
            .ProductScenario-title {
                font-size: 34px;
                width: 792px;
                margin: 0 auto;
                padding: 0;
            }
            .ProductScenario-tabc {
                margin-top: 30px;
            }
            .ProductScenario-tabh {
                margin-top: 33px;
            }
            .ProductScenario-tabh-item {
                font-size: 18px;
                margin: 0 40px;
            }
            .ProductScenario-imgbox {
                .lowcode-img {
                    height: 540px;
                }
            }
            .ProductScenario-msbox {
                bottom: 40px;
                padding: 0 66px;
                .ProductScenario-msbox-l{
                    width: 610px;
                }
                .ProductScenario-ms-h {
                    font-size: 30px;
                }
                .ProductScenario-ms-c {
                    font-size: 20px;
                }
                .editor-link {
                    min-width: 124px;
                    padding: 9px 12px;
                    font-size: 18px;
                    border-radius: 4.35px;
                    &:hover {
                        opacity: 0.85;
                    }
                }
            }
        }
        @include devices(pad) {
            width: 100vw;
            padding: 40px 24px;
            .ProductScenario-title {
                font-size: 22px;
            }
            .ProductScenario-tabh {
                justify-content: flex-start;
                margin-top: 28px;
            }
            .ProductScenario-tabh-item {
                font-size: 14px;
                padding-bottom: 8px;
                margin: 0 18px;
            }
            .ProductScenario-tabc {
                margin-top: 30px;
            }
            .ProductScenario-tabc-item {
                width: 100%;
                padding: 0 94px;
                margin: 0 auto;
            }
            .ProductScenario-imgbox {
                .lowcode-img {
                    height: 220px;
                    border-radius: 8px;
                }
            }
            .ProductScenario-msbox {
                position: static;
                display: block;
                color: #000;
                padding: 0;
                text-align: center;
                margin-top: 24px;
                .ProductScenario-msbox-l {
                    text-align: center !important;
                }
                .ProductScenario-ms-h {
                    font-size: 20px;
                    line-height: 1.2;
                    color: #000 !important;
                }
                .ProductScenario-ms-c {
                    display: block;
                    margin: 10px auto 0;
                    color: #000 !important;
                }
                .editor-link {
                    min-width: 120px;
                    margin-top: 32px;
                    background-color: #000;
                    color: #fff !important;
                    border-color: #000 !important;
                    font-size: 14px;
                }
            }
        }
        @include devices(h5) {
            padding: 40px 24px;
            .ProductScenario-title{
                padding: 0;
            }
            .ProductScenario-tabc{
                margin-top: 14px;
                &.single{
                    margin-top: 30px;
                }
            }
            .ProductScenario-tabc-item{
                padding: 0;
            }
        }
    }
}