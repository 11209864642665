
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.content {
    :global {
        display: block;
        width: 100%;
        margin: 0 auto;
        padding: 0;
        background-color: #f3f4f7;
        overflow: hidden;
        .ProductScenario-title {
            color: #000;
            text-align: center;
            font-size: 28px;
            line-height: 1.1;
            font-family: var(--ff-woff2-EB);
            padding: 0 114px;
        }
        .ProductScenario-item-active {
            border: 2px solid #2468f2;
            z-index: 99;
        }
        .ProductScenarioSlide-content {
            padding: 0 120px;
            margin: 40px auto 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            overflow-x: auto;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
                scrollbar-width: 0;
            }
        }
        .ProductScenarioSlide-item {
            width: 356px;
            height: 318px;
            border-radius: 14px;
            overflow: hidden;
            position: relative;
            flex: none;
        }
        .round-3 {
            .ProductScenarioSlide-item-text {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                padding: 30px;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 29%, rgba(0, 0, 0, 0.5) 63%, #000 100%);
            }
            .ProductScenarioSlide-item-title {
                color: #fff;
                font-family: var(--ff-woff2-B);
                font-size: 20px;
                font-weight: 500;
                line-height: 1.3;
                white-space: pre-wrap;
                word-break: break-word;
                margin-bottom: 10px;
            }
            .ProductScenarioSlide-item-desc {
                color: #cfd0d2;
                font-family: var(--ff-woff2-M);
                font-size: 14px;
                line-height: 1.28;
                margin-bottom: 20px;
                white-space: pre-wrap;
            }
            .ProductScenarioSlide-item-btns {
                display: flex;
                gap: 20px;
                .ProductScenarioSlide-item-btn1 {
                    display: flex;
                    padding: 6px 16px;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    font-family: var(--ff-woff2-M);
                    font-size: 14px;
                    border-radius: 4px;
                    border: 1px solid #fff;
                    cursor: pointer;
                }
                .ProductScenarioSlide-item-btn2 {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    color: #fff;
                    font-family: var(--ff-woff2-M);
                    font-size: 14px;
                    cursor: pointer;
                }
            }
        }

        .round-4,
        .round-5,
        .round-6 {
            &.ProductScenarioSlide-content .ProductScenarioSlide-item {
                width: 285px;
            }
            .ProductScenarioSlide-item-text {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                padding: 30px;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 29%, rgba(0, 0, 0, 0.5) 63%, #000 100%);
            }
            .ProductScenarioSlide-item-title {
                color: #fff;
                font-family: var(--ff-woff2-B);
                font-size: 20px;
                font-weight: 500;
                line-height: 1.3;
                white-space: pre-wrap;
                word-break: break-word;
                margin-bottom: 10px;
            }
            .ProductScenarioSlide-item-desc {
                color: #cfd0d2;
                font-family: var(--ff-woff2-M);
                font-size: 14px;
                line-height: 1.28;
                margin-bottom: 20px;
                white-space: pre-wrap;
            }
            .ProductScenarioSlide-item-btns {
                display: flex;
                gap: 20px;
                .ProductScenarioSlide-item-btn1 {
                    display: flex;
                    padding: 6px 16px;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    font-family: var(--ff-woff2-M);
                    font-size: 14px;
                    border-radius: 4px;
                    border: 1px solid #fff;
                    cursor: pointer;
                    html[site='it'] &,
                    html[site='de'] &,
                    html[site='fr'] &,
                    html[site='es'] & {
                        font-size: 12px;
                    }
                }
                .ProductScenarioSlide-item-btn2 {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    color: #fff;
                    font-family: var(--ff-woff2-M);
                    font-size: 14px;
                    cursor: pointer;
                    html[site='it'] &,
                    html[site='de'] &,
                    html[site='fr'] &,
                    html[site='es'] & {
                        font-size: 12px;
                    }
                }
            }
        }

        @include devices(desktop-max) {
            .ProductScenario-title {
                font-size: 34px;
                width: 792px;
                margin: 0 auto;
                padding: 0;
            }
            .ProductScenarioSlide-content {
                padding: 0 calc(50vw - 600px);
                scrollbar-width: none;
                .ProductScenarioSlide-item {
                    width: 386px;
                    height: 344px;
                }
            }
        }
        @include devices(pad) {
            & {
                width: 100%;
                padding: 0;
                margin-left: auto;
                margin-right: auto;
            }
            .ProductScenario-title {
                font-size: 22px;
            }
            .ProductScenarioSlide-content {
                max-width: none;
                width: auto;
                overflow-x: auto;
                gap: 16px;
                margin: 0 auto;
                padding: 30px 48px 0;
                scrollbar-width: none;
                &::-webkit-scrollbar {
                    display: none;
                    scrollbar-width: 0;
                }
                .ProductScenarioSlide-item {
                    width: 328px;
                    flex: 1 0 300px;
                    height: 300px;
                    border-radius: 8px;
                }
            }
            .round-3,
            .round-4,
            .round-5,
            .round-6 {
                .ProductScenarioSlide-item-text {
                    padding: 20px;
                }
                .ProductScenarioSlide-item-title {
                    font-size: 16px;
                }
                .ProductScenarioSlide-item-desc {
                    font-size: 12px;
                }
            }
        }
        @include devices(h5) {
            .ProductScenario-title {
                padding: 0;
            }
            .ProductScenarioSlide-content {
                display: flex;
                padding: 30px 24px 0;
                overflow-x: auto;
                scrollbar-width: none;
                gap: 16px;
                &::-webkit-scrollbar {
                    display: none;
                    scrollbar-width: 0;
                }
                .ProductScenarioSlide-item {
                    width: 320px;
                    height: 300px;
                    flex: 1 0 320px;
                    border-radius: 8px;
                }
            }
            .round-3,
            .round-4,
            .round-5,
            .round-6 {
                .ProductScenarioSlide-item-text {
                    padding: 20px;
                }
            }
        }
    }
}
