
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.content {
    :global {
        display: block;
        position: sticky;
        // top: 50px;
        z-index: 88;
        .bar-anchor-in {
            display: flex;
            padding: 0 24px;
            overflow-x: auto;
            flex-wrap: nowrap;
            align-items: center;
            font-family: var(--ff-woff2-B);
            &::-webkit-scrollbar {
                display: none;
            }
            .bar-anchor-item:first-child {
                margin-left: auto;
            }
            .bar-anchor-item:last-child {
                margin-right: auto;
            }
        }
        .bar-anchor-item {
            height: 70px;
            display: flex;
            align-items: center;
            padding: 0 20px;
            white-space: nowrap;
            line-height: 20px;
            color: #333;
            border-bottom: 1px solid transparent;
        }
        @include devices(pad) {
            .bar-anchor-in {
                gap: 10px;
            }
            .bar-anchor-item {
                height: 50px;
            }
        }
        @include devices(h5) {
            .bar-anchor-in {
                padding: 0;
            }
            .bar-anchor-item:hover {
                background-color: transparent;
                color: inherit;
            }
        }
    }
}