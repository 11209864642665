
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
.lowcodelearnmore {
    :global {
        min-width: 136px;
        color: #fff;
        font-family: var(--ff-woff2-M);
        font-size: 16px;
        padding: 10px 22px;
        line-height: 18px;
        border-radius: 5px;
        border: 1px solid #fff;
        margin: 0 12px;
    }
}
