
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.content {
    :global {
        display: block;
        position: relative;
        background-color: #fff;
        padding: 40px 0;
        .shopify-card-in {
            margin: 0 auto;
            max-width: var(--max-width-pc);
            padding: var(--padding-pc);
            font-family: var(--ff-woff2-M);
        }
        .shopify-card-item {
            display: flex;
            align-items: center;
            background-color: #f5f5f5;
            margin-bottom: 10px;
        }
        .shopify-card-item-l {
            position: relative;
            flex: 1;
            height: 372px;
        }
        .shopify-card-item-r {
            flex: 1;
            padding: 0 20px 0 62px;
        }
        .shopify-card-item-title {
            font-size: 24px;
            line-height: 30px;
        }
        .shopify-card-item-subtitle1 {
            font-size: 14px;
            line-height: 1.2;
            padding-right: 30px;
            font-family: var(--ff-woff2-L);
            margin-top: 6px;
            color: #757575;
            white-space: pre-line;
        }
        .shopify-card-item-price1 {
            font-size: 32px;
            margin-top: 57px;
        }
        .shopify-card-item-price2 {
            color: #949491;
            font-size: 16px;
            line-height: 1.3;
        }
        .shopify-card-item-btns {
            margin-top: 13px;
        }
        .shopify-buy-now {
            color: #fff;
        }
        .shopify-save {
            position: absolute;
            top: 0;
            left: 0;
        }
        @include devices(desktop-max) {
            .shopify-card-in {
            }
        }
        @include devices(pad) {
            .shopify-card-in {
                padding: var(--padding-pad);
            }
            .shopify-card-item {
                display: block;
                padding-bottom: 20px;
            }
        }
        @include devices(h5) {
            .shopify-card-in {
                padding: var(--padding-h5);
            }
        }
    }
}