
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.content {
    :global {
        display: block;
        position: relative;
        height: 600px;
        color: #fff;
        .banner-youtube-item {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        .banner-youtube-img {
            height: 100%;
        }
        .banner-youtube-title1 {
            font-size: 32px;
            margin-top: 4px;
            opacity: 0;
            transform: translateY(120px);
            transition: opacity .8s linear 0.2s, transform .4s cubic-bezier(.26,.67,.48,.91) 0.2s;
        }
        .banner-youtube-title2 {
            font-size: 18px;
            margin-top: 4px;
            opacity: 0;
            transform: translateY(120px);
            transition: opacity .8s linear 0.1s, transform .4s cubic-bezier(.26,.67,.48,.91) 0.1s;
        }
        .banner-youtube-btns {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
            margin-top: 12px;
            opacity: 0;
            transform: translateY(120px);
            transition: opacity .8s linear 0s, transform .4s cubic-bezier(.26,.67,.48,.91) 0s;
        }
        .banner-youtube-btn {
            display: flex;
            padding: 5px 24px;
            line-height: 20px;
            border: 1px solid #fff;
            border-radius: 64px;
            color: inherit;
            cursor: pointer;
        }
        .banner-youtube-msg {
            position: absolute;
            top: 40px;
            left: 0;
            width: 100%;
            text-align: center;
            color: #fff;
            &.isEnter {
                .banner-youtube-title1, .banner-youtube-title2, .banner-youtube-btns {
                    opacity: 1;
                    transform: translateY(0);
                }
                .banner-youtube-title1 {
                    transition: opacity .8s linear 0s, transform .4s cubic-bezier(.26,.67,.48,.91) 0s;
                }
                .banner-youtube-title2 {
                    transition: opacity .8s linear 0.1s, transform .4s cubic-bezier(.26,.67,.48,.91) 0.1s;
                }
                .banner-youtube-btns {
                    transition: opacity .8s linear 0.2s, transform .4s cubic-bezier(.26,.67,.48,.91) 0.2s;
                }
            }
        }
        @include devices(pad) {
            .banner-youtube-title1 {
                font-size: 24px;
            }
            .banner-youtube-title2 {
                font-size: 16px;
            }
        }
    }
}