$desktop-max-width: 1440px;
$desktop-width: 1024px;
$tablet-width: 768px;
$mobile-width: 375px;

@mixin devices($type) {
    // 1440+ 超大屏
    @if $type == desktop-max {
        @media only screen and (width > $desktop-max-width) {
            @content;
        }
    }

    // 1440+ 超大屏
    @if $type == pc {
        @media only screen and (width > $desktop-max-width) {
            @content;
        }
    }

    // (1024 ~ 1440] 大屏
    @if $type == desktop {
        @media ((width > $desktop-width) and (width <= $desktop-max-width)) {
            @content;
        }
    }

    // (1440 ~ ) only 1920
    @if $type == only1920 {
        @media only screen and (width > $desktop-max-width) {
            @content;
        }
    }

    // (1024 ~ 1440] only 1366
    @if $type == only1366 {
        @media ((width > $desktop-width) and (width <= $desktop-max-width)) {
            @content;
        }
    }

    // [768 ~ 1024] only 768
    @if $type == only768 {
        @media ((width <= $desktop-width) and (width >= $tablet-width)) {
            @content;
        }
    }

    // [768 ~ 1024] pad
    @if $type == pad {
        @media (width <= $desktop-width) {
            @content;
        }
    }

    // [0 ~ 768) h5
    @if $type == h5 {
        @media (width < $tablet-width) {
            @content;
        }
    }
}
