
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.FormCommon {
    :global {
        display: flex;
        flex-flow: wrap;
        max-width: 1200px;
        margin: 0 auto;
        font-family: var(--ff-woff2-M);
        .form-common-item {
            width: 50%;
            padding-right: 25px;
            font-family: var(--ff-woff2-M);
            &.form-common-100 {
                width: 100%;
            }
        }
        .form-common-input {
            width: 100%;
            height: 47px;
            background-color: transparent;
            border: 1px solid #ddd;
            outline: none;
            padding: 0 12px;
            border-radius: 8px;
            &:focus {
                border-color: #3F68E0;
            }
        }
        .form-common-select {
            position: relative;
            .form-common-select-icon {
                position: absolute;
                top: 50%;
                right: 20px;
                transform: translate(0, -50%);
                cursor: pointer;
            }
            .form-common-select-input-box {
                position: relative;
            }
            .form-common-select-input {
                width: 100%;
                height: 47px;
                background-color: transparent;
                border: 1px solid #ddd;
                outline: none;
                padding: 0 12px;
                border-radius: 8px;
                
            }
            .form-common-select-list {
                display: none;
                position: absolute;
                top: 58px;
                left: 0;
                z-index: 10;
                width: 100%;
                background-color: #fff;
                border-radius: 8px;
                max-height: 235px;
                overflow-y: auto;
            }
            .form-common-select-item {
                padding: 14px 12px;
                line-height: 20px;
                font-size: 16px;
                cursor: pointer;
                &:hover {
                    background-color: #ddd;
                }
            }
            &.form-common-select-active {
                .form-common-select-list {
                    display: block;
                }
            }
        }
        
        .form-common-error {
            min-height: 30px;
            padding: 5px 0;
            .form-common-error-line {
                display: none;
                gap: 8px;
                line-height: 20px;
                color: #F8200C;
                align-items: center;
                svg {
                    min-width: 14px;
                }
            }
        }
        .form-common-radio {
            .form-common-radio-title {
                font-family: var(--ff-woff2-M);
                font-size: 16px;
                line-height: 20px;
            }
            .form-common-radio-list {
                display: flex;
                gap: 20px;
                margin-top: 16px;
                padding-left: 20px;
            }
            .form-common-radio-input {
                position: relative;
                display: flex;
                align-items: center;
                width: 20px;
                min-width: 20px;
                height: 20px;
                border-radius: 4px;
                border: 1px solid #626262;
                svg {
                    visibility: hidden;
                    position: absolute;
                    top: -1px;
                    left: -1px;
                }
            }
            .form-common-radio-item {
                display: flex;
                gap: 8px;
                align-items: center;
                cursor: pointer;
                &.form-common-radio-item-check {
                    .form-common-radio-input {
                        svg {
                            visibility: visible;
                        }
                    }
                }
            }
        }
        .form-common-textarea {
            position: relative;
            .form-common-textarea-input {
                width: 100%;
                min-height: 150px;
                border: 1px solid #ddd;
                outline: none;
                padding: 14px 12px;
                border-radius: 8px;
                background-color: transparent;
            }
            .form-common-textarea-maxlen {
                position: absolute;
                bottom: 12px;
                right: 0;
                width: 100%;
                display: flex;
                text-align: right;
                justify-content: flex-end;
                font-size: 14px;
                color: #757575;
            }
        }
        .form-common-content.form-common-show-error, .form-common-item.form-common-show-error {
            .form-common-input, .form-common-select-input, .form-common-textarea-input {
                background-color: rgba(248, 32, 12, 0.10);
                border-color: #F8200C;
            }
            .form-common-error {
                .form-common-error-line {
                    display: flex;
                }
            }
            .form-common-input {
                border-color: #F8200C;
            }
        }
        .form-common-submit-box {
            width: 100%;
        }
        .form-common-submit-btn {
            width: 100%;
            height: 50px;
            line-height: 50px;
            border-radius: 6px;
            background-color: #3F68E0;
            cursor: pointer;
            text-align: center;
            margin: 0 auto;
            color: #fff;
            font-size: 16px;
        }
        .form-common-privacy {
            display: flex;
            align-items: center;
            width: 100%;
            gap: 8px;
            margin-bottom: 16px;
            .form-common-privacy-l {
                position: relative;
                display: flex;
                align-items: center;
                width: 20px;
                height: 20px;
                border-radius: 4px;
                border: 1px solid #626262;
                svg {
                    visibility: hidden;
                    position: absolute;
                    top: -1px;
                    left: -1px;
                }
            }
            .form-common-privacy-r {
                color: #949494;
                font-size: 12px;
                a {
                    color: #3F68E0;
                }
            }
            &.form-common-privacy-active {
                .form-common-privacy-l {
                    svg {
                        visibility: visible;
                    }
                }
            }
        }
        .form-common-subscribe {
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: pointer;
            margin-bottom: 40px;
            .form-common-subscribe-l {
                position: relative;
                display: flex;
                align-items: center;
                width: 20px;
                min-width: 20px;
                height: 20px;
                border-radius: 4px;
                border: 1px solid #626262;
                svg {
                    visibility: hidden;
                    position: absolute;
                    top: -1px;
                    left: -1px;
                }
            }
            .form-common-subscribe-r {
                color: #949494;
                font-size: 12px;
            }
            &.form-common-subscribe-active {
                .form-common-subscribe-l {
                    svg {
                        visibility: visible;
                    }
                }
            }
        }
        .form-common-recaptcha {
            width: 100%;
            margin-bottom: 30px;
        }
        @include devices(desktop-max) {
            .form-common-error {
                min-height: 40px;
                padding: 10px 0;
            }
        }
        @include devices(pad) {
            .form-common-item {
                padding-right: 0;
            }
            .form-common-item.form-common-50 {
                width: 100%;
            }
        }
        @include devices(h5) {}
    }
    &:global(.from-common-theme-black) {
        :global {
            background-color: #181818;
            color: #fff;
            .form-common-input, .form-common-select-input, .form-common-textarea-input {
                border-color: #343434;
                &:focus {
                    border-color: #3F68E0;
                }
            }
            .form-common-radio {
                .form-common-radio-input {
                    background-color: #222223;
                }
            }
            .form-common-select-list {
                background-color: #232323;
                color: #fff;
                .form-common-select-item {
                    &:hover {
                        background-color: #2F2F31;
                    }
                }
            }
        }
    }
}
