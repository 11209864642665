
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.content {
    :global {
        display: block;
        text-align: center;
        .RewardLists-title {
            width: calc(100vw - 256px);
            margin: 0 auto;
            font-family: var(--ff-woff2-EB);
            font-size: 22px;
            line-height: 1.1;
            color: #000;
        }
        .RewardLists-in {
            margin: 30px auto 0;
            width: calc(100vw - 256px);
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 50px;
            .RewardLists-item {
                width: 90px;
                height: 90px;
                .lowcode-img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        
        @include devices(desktop-max) {
            .RewardLists-title {
                font-size: 24px;
                width: 792px;
            }
            .RewardLists-in {
                width: 1200px;
                margin-top: 40px;
                gap: 54px;
                .RewardLists-item {
                    width: 100px;
                    height: 100px;
                }
            }
        }
        @include devices(pad) {
            .RewardLists-title {
                font-size: 18px;
                width: calc(100vw - 96px);
            }
            .RewardLists-in {
                width: calc(100vw - 96px);
                margin-top: 20px;
                gap: 16px;
                .RewardLists-item {
                    width: 79px;
                    height: 79px;
                }
            }
        }
        @include devices(h5) {
            .RewardLists-title {
                font-size: 18px;
                width: calc(100vw - 48px);
            }
            .RewardLists-in {
                width: calc(100vw - 48px);
                margin-top: 14px;
                gap: 10px;
            }
        }
    }
}