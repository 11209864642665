
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.content {
    :global {
        display: block;
        position: relative;
        color: #333;
        .product-whatbox-in {
            margin: 0 auto;
            max-width: var(--max-width-pc);
            padding: var(--padding-pc);
        }
        .product-whatbox-title {
            font-size: 24px;
            text-align: center;
            margin-bottom: 24px;
        }
        .product-whatbox-subTitle {
            font-size: 24px;
            margin-bottom: 24px;
        }
        .product-whatbox-card {
            padding: 20px;
            background-color: #f5f5f5;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .product-whatbox-card-title {
                min-height: 40px;
                text-align: left;
                font-size: 14px;
                line-height: 20px;
            }
            .product-whatbox-card-img {
                margin: 0 auto;
            }
        }
        .product-whatbox-list {
            display: flex;
            gap: 10px;
        }
        .product-whatbox-l {
            flex: 4;
            .product-whatbox-card {
                height: 100%;
            }
        }
        .product-whatbox-r {
            flex: 6;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 10px;
            &.product-whatbox-len-2 {
                grid-template-columns: 1fr 1fr;
            }
        }
        @include devices(pad) {
            .product-whatbox-in {
                padding: var(--padding-pad);
            }
            .product-whatbox-list {
                display: block;
            }
            .product-whatbox-r {
                margin-top: 10px;
                grid-template-columns: 1fr 1fr;
            }
        }
        @include devices(h5) {
            .product-whatbox-in {
                padding: var(--padding-h5);
            }
            .product-whatbox-card {
                padding: 16px;
            }
            .product-whatbox-r {
                margin-top: 10px;
                gap: 10px;
            }
        }
    }
    &:global(.product-whatbox-black) {
        :global {
            color: #fff;
            
            .product-whatbox-subTitle {
                color: #afafaf;
            }
            .product-whatbox-card {
                background-color: #181818;
            }
        }
    }
}